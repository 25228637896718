import jwtDecode from 'jwt-decode';
export const checkLoginStatus = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
        try {
            const decodedUser = JSON.parse(storedUser);
            return true;
        } catch (error) {
            console.error('Invalid user data:', error);
            return false;
        }
    } else {
        return false;
    }
};
