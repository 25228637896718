import React, { useState } from "react";
import "./Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import axios from 'axios';
import { Box, Button, Grid, Link, Stack, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Rating, FormControl, MenuItem, Select } from "@mui/material";
import { Instagram } from "@mui/icons-material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { checkLoginStatus } from '../../Services/Checklogin';
const Footer = () => {
  const baseURL = process.env.REACT_APP_GCW_BASE_URL;
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);
  const [comments, setComments] = useState("");
  const [contactOption, setContactOption] = useState("");
  const [commentsError, setCommentsError] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  const initialRatings = {
    Website: 0,
    Business: 0,
    Service: 0,
    Payment: 0,
    Content: 0,
  };
  const [ratings, setRatings] = useState(initialRatings);
  const accountName = "GCW";
  const baseLinkedinURL = "https://www.linkedin.com/company/global-carbon-warriors/";
  const linkedinURL = baseLinkedinURL + accountName;
  const basetwitURL = "https://twitter.com/Gcarbonwarrior?t=Hu-ENy-FiNxj7cSgnzS7CQ&s=08";
  const twitURL = basetwitURL + accountName;
  const instaURL = "https://www.instagram.com/global_carbon_warriors/";
  const isMobileView = useMediaQuery('(max-width:768px)');
  const handleButtonClick = () => {
    if (checkLoginStatus()) {
      window.open('/investor-ready-test');
    } else {
      window.open("/login");
    }
  };

  const handleApplyforfund = () => {
    if (checkLoginStatus()) {
      window.open('/profile/cleantech/apply-for-fund');
    } else {
      window.open("/login");
    }
  };
  const handleClickOpenFeedback = () => {
    setRatings(initialRatings);
    setComments("");
    setCommentsError(false);
    setRatingError(false);
    setOpenFeedback(true);
  };
  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };
  const handleClickOpenContact = () => {
    setOpenContact(true);
  };
  const handleCloseContact = () => {
    setOpenContact(false);
  };
  const handleCloseThankYou = () => {
    setOpenThankYou(false);
  };
  const handleRatingChange = (category, newValue) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [category]: newValue,
    }));
  };
  const handleCommentChange = (event) => {
    setComments(event.target.value);
    setCommentsError(false);
  };
  const handleContactOptionChange = (event) => {
    setContactOption(event.target.value);
  };
  const handleSubmitFeedback = () => {
    const allCategoriesRated = Object.values(ratings).every(rating => rating > 0);
    if (!allCategoriesRated) {
      setRatingError(true);
    } else if (!comments) {
      setCommentsError(true);
    } else {
      const feedbackData = {
        website: ratings.website,
        business: ratings.business,
        service: ratings.service,
        payment: ratings.payment,
        content: ratings.content,
        comments: comments
      };
  
      axios.post(`${baseURL}/feedback`, feedbackData, {
        headers: {
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          console.log('Feedback submitted successfully:', response.data);
          handleCloseFeedback();
          setOpenThankYou(true);
        })
        .catch(error => {
          console.error('Error submitting feedback:', error);
          if (error.response) {
            console.error('Error response data:', error.response.data);
          } else if (error.request) {
            console.error('Error request data:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        });
    }
  };
  
  
  const handleDialogClose = () => {
    setContactOption('');
    handleCloseContact();
  };
  const contactEmails = {
    General: "mailto:general@globalcarbonwarriors.com",
    Enquiry: "mailto:enquiry@globalcarbonwarriors.com",
    Partnership: "mailto:partners@globalcarbonwarriors.com",
    Investment: "mailto:investment@globalcarbonwarriors.com",
  };
  return (
    <footer className="footer" style={{ width: "100%" }}>
      <Box className="footer-links" marginTop='2%'>
        <Grid
          container
          spacing={isMobileView ? 0 : 2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={isMobileView ? "95%" : "70%"}
          sx={{
            alignItems: "center",
            textAlign: 'center'
          }}
        >
          <Grid item xs={4} sm={4} md={2.5}>
            <Button
              aria-label="VideoGallery"
              sx={{ fontSize: isMobileView ? '0.6rem' : '0.9rem', padding: isMobileView ? '0' : 'default' }}
              variant="text"
              color="inherit"
              href="/videos-page"
              target="_blank"
              style={{ whiteSpace: "nowrap" }}
            >
              Video Gallery
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={3}>
            <Button
              aria-label="InvestorReadyTest"
              sx={{ fontSize: isMobileView ? '0.6rem' : '0.9rem', padding: isMobileView ? '0' : 'default' }}
              variant="text"
              color="inherit"
              onClick={handleButtonClick}
              href="/investor-ready-test"
              target="_blank"
              style={{ whiteSpace: "nowrap" }}
            >
              Investor-Ready Test
            </Button>
          </Grid>
          <Grid item xs={4} sm={4} md={1.5}>
            <Button
              aria-label="Feedback"
              sx={{ fontSize: isMobileView ? '0.6rem' : '0.9rem', padding: isMobileView ? '0' : 'default' }}
              variant="text"
              color="inherit"
              onClick={handleClickOpenFeedback}
              style={{ whiteSpace: "nowrap" }}
            >
              Feedback
            </Button>
          </Grid>
          <Grid item xs={5} sm={5} md={2}>
            <Button
              aria-label="ContactUS"
              sx={{ fontSize: isMobileView ? '0.6rem' : '0.9rem', padding: isMobileView ? '0' : 'default' }}
              variant="text"
              color="inherit"
              onClick={handleClickOpenContact}
              style={{ whiteSpace: "nowrap" }}
            >
              Contact Us
            </Button>
          </Grid>
          <Grid item xs={5} sm={5} md={3}>
            <Button
              aria-label="ApplyForFundraiser"
              sx={{ fontSize: isMobileView ? '0.6rem' : '0.9rem', padding: isMobileView ? '0' : 'default' }}
              variant="text"
              color="inherit"
              onClick={handleApplyforfund}
            >
              Apply for Fundraise
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Typography className="footer-follow">Follow us on</Typography>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
      >
        <Box className="footer-images" style={{ marginBottom: "10px" }}>
          <Link href={linkedinURL} target="_blank" aria-label="LinkedIn">
            <LinkedInIcon className="TweetIcon" />
          </Link>
          <Link href={instaURL} target="_blank" aria-label="Instagram">
            <Instagram className="TweetIcon" />
          </Link>
          <Link href={twitURL} target="_blank" aria-label="Twitter">
            <XIcon className="TweetIcon" />
          </Link>
        </Box>

      </Stack>
      <Typography className="footer-follow"></Typography>
      <Box className="footer-text">
        <Box >
          <Typography className="copyright" style={{ fontSize: isMobileView ? '0.5rem' : '1rem' }}>&copy; 2024 Global Carbon Warriors. All rights reserved.</Typography>
          <Typography className="copyright" style={{ fontSize: isMobileView ? '7px' : '12px', textAlign:"center" }}>Images and Videos from Freepik and Pictory</Typography>
        </Box>

      </Box>
      <Dialog open={openFeedback} onClose={handleCloseFeedback}>
        <DialogTitle fontFamily="Montserrat" fontWeight="bolder" color="#156D15">Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText fontFamily="Montserrat">
            We appreciate your feedback. Please provide a rating for each category below.
          </DialogContentText>
          {Object.keys(initialRatings).map((category) => (
            <Box key={category} sx={{ marginBottom: '10px' }}>
              <Typography>{category}</Typography>
              <Rating
                name={`${category}-rating`}
                value={ratings[category]}
                onChange={(event, newValue) => handleRatingChange(category, newValue)}
                sx={{ fontSize: "2rem" }} 
              />
            </Box>
          ))}
          {ratingError && (
            <Typography color="error" variant="body2">
              Please provide a rating for each category.
            </Typography>
          )}
          <TextField
            margin="dense"
            aria-label="feedback Comments"
            id="feedback-comments"
            label="Comments"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={comments}
            onChange={handleCommentChange}
            variant="standard"
            style={{ marginTop: '25px' }}
            error={commentsError}
            helperText={commentsError && "Please fill out the comments section."}
          />
        </DialogContent>
        <DialogActions>
          <Button aria-label="Cancel" variant="contained" className="feedback-cl-btn" onClick={handleCloseFeedback}>Cancel</Button>
          <Button
            aria-label="Submit"
            variant="contained"
            className="feedback-submit-btn"
            onClick={handleSubmitFeedback}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openThankYou} onClose={handleCloseThankYou}>
        <DialogTitle fontFamily="Montserrat" fontWeight="bolder" color="#156D15">Thank You!</DialogTitle>
        <DialogContent>
          <DialogContentText fontFamily="Montserrat">
            Thank you so much for submitting your feedback.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button aria-label="Close" variant="contained" onClick={handleCloseThankYou} className="feedback-submit-btn" >Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openContact} onClose={handleDialogClose} maxWidth="sm" fullWidth>
        <DialogTitle
          fontFamily="Montserrat"
          fontWeight="bolder"
          color="#156D15"
          sx={{ fontSize: '24px' }}
        >
          Contact Us
        </DialogTitle>
        <DialogContent sx={{ height: '120px' }}>
          <DialogContentText fontFamily="Montserrat" sx={{ fontSize: '16px' }}>
            Please select a category.
          </DialogContentText>
          <FormControl fullWidth margin="dense">
            <Select
              value={contactOption}
              onChange={handleContactOptionChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="" disabled>
                Select Category
              </MenuItem>
              <MenuItem value="General">General</MenuItem>
              <MenuItem value="Enquiry">Enquiry</MenuItem>
              <MenuItem value="Partnership">Partnership</MenuItem>
              <MenuItem value="Investment">Investment</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label="Close"
            variant="contained"
            onClick={handleDialogClose}
            className="feedback-cl-btn"
            sx={{ height: '40px', width: '100px' }}
          >
            Cancel
          </Button>
          <Button
            aria-label="Email"
            variant="contained"
            className="feedback-submit-btn"
            onClick={() => {
              window.location.href = contactEmails[contactOption];
              handleDialogClose();
            }}
            disabled={!contactOption}
            sx={{ height: '40px', width: '100px' }}
          >
            Email
          </Button>
        </DialogActions>
      </Dialog>
    </footer>
  );
};
export default Footer;