import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const DynamicCanonical = () => {
  const location = useLocation();
  const baseURL = "https://globalcarbonwarriors.com"; 

  useEffect(() => {
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      canonicalLink.href = `${baseURL}${location.pathname}`;
    } else {
      const link = document.createElement('link');
      link.rel = 'canonical';
      link.href = `${baseURL}${location.pathname}`;
      document.head.appendChild(link);
    }
  }, [location]);

  return (
    <Helmet>
      <link rel="canonical" href={`${baseURL}${location.pathname}`} />
    </Helmet>
  );
};

export default DynamicCanonical;
