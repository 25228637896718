import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { keyframes } from "@emotion/react";
import "./loader.css";

const Loader = () => {
  const move = keyframes`
    0% {
      width: 0%;
    }
    100% {
      width: 100%;
    }
  `;

  return (
    <Box className="box-style">
      <LinearProgress
        sx={{
          position: "absolute",
          width: { md: "30%", xs: "40%" },
          top: "62%",
          height: { md: "21px", xs: "17px" },
          overflow: "hidden",
          borderRadius: "5px",
          "& .MuiLinearProgress-bar": {
            backgroundImage: `linear-gradient(to bottom, #FFF61A, #156D15)`,
            animation: `${move} 2s linear forwards`,
          },
        }}
      />
    </Box>
  );
};

export default Loader;
