import React, { useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Loader from "./pages/Loader/loader";
import DynamicCanonical from "./DynamicCanonical";
import Blog19 from "./pages/Blog/Blog19";
import Blog20 from "./pages/Blog/Blog20";

const GoogleVerification = lazy(() => import("./components/GoogleVerification"));
const CostOfClimateChange = lazy(() => import("./pages/CostOfClimateChange/CostOfClimateChange"));
const Login = lazy(() => import("./pages/Login/Login"));
const ProfileHome = lazy(() => import("./pages/ProfileHome/ProfileHome"));
const ScrollingProfiles = lazy(() => import("./components/ScrollingProfiles/scrolling-apex-panel"));
const RegistrationHome = lazy(() => import("./pages/Registration/RegistrationHome"));
const RegCoachesAndConsultants = lazy(() => import("./pages/Registration/RegCoachesAndConsultants"));
const RegMentors = lazy(() => import("./pages/Registration/RegMentors"));
const RegCleanTechHub = lazy(() => import("./pages/Registration/RegCleanTechHub"));
const RegRetailInvestor = lazy(() => import("./pages/Registration/RegRetailInvestor"));
const RegAngelInvestor = lazy(() => import("./pages/Registration/RegAngelInvestor"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const Blog = lazy(() => import("./pages/Blog/Blog"));
const ClimateChangeFacts = lazy(() => import("./pages/ClimateChangeFacts/ClimateChangeFacts"));
const StartUpTrends = lazy(() => import("./pages/StartUpTrends/StartUpTrends"));
const NetZeroCarbonNeautral = lazy(() => import("./pages/NetZeroCarbonNeutral/NetZeroCarbonNeutral"));
const WhatTheFrontrunnersSay = lazy(() => import("./pages/WhatTheFrontrunnersSay/WhatTheFrontrunnersSay"));
const CleanTechnologies = lazy(() => import("./pages/CleanTechnologies/CleanTechnologies"));
const ForStartUps = lazy(() => import("./pages/ForStartups/ForStartUps"));
const ForProfessional = lazy(() => import("./pages/ForProfessionals/ForProfessional"));
const ForInvestor = lazy(() => import("./pages/ForInvestors/ForInvestor"));
const CarbonCalculator = lazy(() => import("./pages/CarbonFootPrintCalculator/CarbonCalculator"));
const IndividualProfile = lazy(() => import("./pages/ProfileHome/IndividualProfile"));
const Homepage = lazy(() => import("./pages/HomePage/HomePage"));
const Gallery = lazy(() => import("./pages/Gallery/Gallery"));
const InvestorTest = lazy(() => import("./pages/investorReadinessTest/investorReadinessTest"));
const Question = lazy(() => import("./pages/Question/Question"));
const ScoreCard = lazy(() => import("./pages/ScoreCard/Scorecard"));
const VideosPage = lazy(() => import("./pages/VideosPage/VideosPage"));
const ApplyForFundraiser = lazy(() => import("./pages/ApplyForFundraiser/ApplyForFundraiser"));
const Set = lazy(() => import("./pages/Settings/Set"));
const CleanTech = lazy(() => import("./pages/CleanTechProfile/CleanTech"));
const CtEditProfile = lazy(() => import("./pages/CleanTechProfile/CtEditProfile/CtEditProfile"));
const CleanTechHome = lazy(() => import("./pages/CleanTechProfile/CleanTechHome/CleanTechHome"));
const Atrium = lazy(() => import("./pages/Atrium/Atrium"));
const ApplyForFund = lazy(() => import("./pages/ApplyForFund/ApplyForFund"));
const ComingSoon = lazy(() => import("./pages/ComingSoon/coming-soon"));
const AdminHome = lazy(() => import("./pages/AdminHome/AdminHome"));
const VideoComments = lazy(() => import("./pages/AdminHome/VideoComments"));
const ApplyForFundAppln = lazy(() => import("./pages/ApplyForFundAppln/ApplyForFundAppln"));
const ApplicationForFundRaise = lazy(() => import("./pages/AdminHome/ApplicationForFundRaise"));
const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fakeAsyncTask = () => {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    };
    fakeAsyncTask();
  }, []);

  const excludeHeaderFooterRoutes = ["/login", "/question", "/coming-soon"];
  const shouldRenderHeaderFooter = () => {
    return !excludeHeaderFooterRoutes.includes(window.location.pathname);
  };

  return (
    <Router>
      <div className="app">
        {isLoading && <Loader />}
        {shouldRenderHeaderFooter() && <Header />}
        <Suspense fallback={<Loader />}>
          <DynamicCanonical />
          <Routes>
            <Route path="/google345be2f8d631f375.html" element={<GoogleVerification />} />
            <Route path="/" element={<Homepage />} />
            <Route path="/login" element={<MainLayout />} />
            <Route path="/videos-page" element={<VideosPage />} />
            <Route path="/videos-page/:index" element={<VideosPage />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:index" element={<Blog />} />
            <Route path="/climate-change-facts" element={<ClimateChangeFacts />} />
            <Route path="/clean-technologies" element={<CleanTechnologies />} />
            <Route path="/startup-trends" element={<StartUpTrends />} />
            <Route path="/net-zero-and-carbon-neutral" element={<NetZeroCarbonNeautral />} />
            <Route path="/what-the-frontrunners-say" element={<WhatTheFrontrunnersSay />} />
            <Route path="/cost-of-climate-change" element={<CostOfClimateChange />} />
            <Route path="/start-ups" element={<ForStartUps />} />
            <Route path="/professionals" element={<ForProfessional />} />
            <Route path="/investors" element={<ForInvestor />} />
            <Route path="/carbon-calculator" element={<CarbonCalculator />} />
            <Route path="/register" element={<RegistrationHome />} />
            <Route path="/register/coaches-consultants" element={<RegCoachesAndConsultants />} />
            <Route path="/register/mentors" element={<RegMentors />} />
            <Route path="/register/cleantech-hub" element={<RegCleanTechHub />} />
            <Route path="/register/retail-investor" element={<RegRetailInvestor />} />
            <Route path="/register/angel-investor" element={<RegAngelInvestor />} />
            <Route path="/profile" element={<ProfileHome />} />
            <Route path="/profile-individual" element={<IndividualProfile />} />
            <Route path="/scrolling-apex-panel" element={<ScrollingProfiles />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/investor-ready-test" element={<InvestorTest />} />
            <Route path="/question" element={<InvestorReadinessTest />} />
            <Route path="/scorecard" element={<ScoreCard />} />
            <Route path="/settings" element={<Set />} />
            <Route path="/profile/cleantech" element={<CleanTech />} />
            <Route path="/profile/cleantech/edit" element={<CtEditProfile />} />
            <Route path="/profile/cleantech/home" element={<CleanTechHome />} />
            <Route path="/atrium" element={<Atrium />} />
            <Route path="/profile/cleantech/apply-for-fund" element={<ApplyForFund />} />
            <Route path="/profile/cleantech/apply-for-fundraiser/home" element={<ApplyForFundraiser />} />
            <Route path="/coming-soon" element={<ComingSoonPage />} />
            <Route path="/admin/home" element={<AdminHome />} />
            <Route path="/admin/home/video-comments" element={<VideoComments />} />
            <Route path="/blog-article19" element={<Blog19 />} />
            <Route path="/blog-article20" element={<Blog20 />} />
            <Route path="/application-for-fundraise" element={<ApplyForFundAppln />} />
            <Route path="/admin/ApplicationForFundRaise" element={<ApplicationForFundRaise />} />
          </Routes>
        </Suspense>
        {shouldRenderHeaderFooter() && <Footer />}
      </div>
    </Router>
  );
};

const MainLayout = () => {
  return (<Login />);
};

const InvestorReadinessTest = () => {
  return (<Question />);
};

const ComingSoonPage = () => {
  return (<ComingSoon />);
};

export default App;
