import React from "react";
import { Container, Box, Typography } from "@mui/material";

const Blog19 = () => {
  return (
    <Container maxWidth="lg">
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/assets/images/backgrounds/blog19Banner.webp`}
        alt="Banner"
        sx={{
          width: "100%",
          height: "auto",
          mb: 4,
          borderRadius: 2,
        }}
      />
      <Typography variant ="body1" sx={{ textAlign: "justify",  fontFamily: "poppins",mb: 2 }}>
       <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold' }}>
        How Essential Is Mentoring For Cleantech Startups?
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        The culture of entrepreneurship is a fast-growing one, fuelled in part by government policies aimed at helping new businesses, and the increasing availability of financing. Thanks to these key factors, the startup industry has grown significantly in recent years, with cleantech startup numbers in particular, rising gradually in many countries; ideas and innovations literally abounding.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        New ventures are mushrooming well, some of which are new products or services, or simply new ways of doing something that already exists – funding is often available, while startup mentoring isn’t always.
      </Typography>

      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        The importance of mentoring for cleantech entrepreneurs
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        With so much funding seemingly available to help provide support for the entrepreneurial ecosystem, startups can be forgiven for focusing solely on this, when actually, mentorship is desperately needed. When a startup is in its early phases, entrepreneurs will likely face a multitude of risks. If they approach funding without mitigating them, they are bound to give away high stakes for the little fund that they can get.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px', fontWeight: 'bold' }}>
        So should cleantech entrepreneurs be more focused on securing mentorship throughout their startup journey? The simple answer is yes: mentorship is vital if a startup wishes to be successful and experience longevity by having control on its company.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        What a lot of entrepreneurs fail to recognize, is that funding can almost certainly help them develop their business in a short term. Without the necessary guidance from someone more experienced, the funding agency may hijack their business from the direction they actually want it to go in.
      </Typography>

      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        Well known organizations who benefitted from mentorship
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        Ever heard of Bill Gates? Sure you have. The co-founder of software company Microsoft, was mentored by none other than Warren Buffet, himself a successful businessman, investor, and philanthropist. Even the likes of Steve Jobs benefitted from mentorship, and it’s hard to think of two more fitting examples of why the right guidance at the right time, is so essential for anyone trying to create a successful business.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        While the majority of cleantech entrepreneurs won’t be mentored by such greats of the business world, the importance of having that guidance is clear. In fact, analysis has shown over the years, that startups with founders who were mentored by experienced professionals, are as many as 3 times more likely to expand, and make names for themselves within the entrepreneurial world.
      </Typography>

      <Typography variant="h5" gutterBottom style={{ fontWeight: 'bold' }}>
        What can happen when startups don’t have mentors?
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        While some startups might strike it lucky and become successful without ever seeking guidance and support from a suitable mentor, it’s perhaps more likely that those going it alone, will make mistakes due to a lack of experience, and guidance. Majority of startups fail during the early stages of growth, which is the reason why this phase is often referred as valley of death.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        Knowledgeable mentors can give advice to entrepreneurs based upon their own experiences, and help them avoid the kind of mistakes that perhaps they made (or might have made) when going it alone.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        Even a startup with a groundbreaking idea that has heaps of potential, can go horribly wrong in an instant without the right kind of guidance.
      </Typography>

      <Typography variant="body1" gutterBottom style={{ fontSize: '18px' }}>
        There’s so much more to entrepreneurship in building a company; the entrepreneur themselves is embarking on a journey of which, more often than not, the final destination is entirely out of their control. But, with the help of professional startup advisory services, they can make well-informed decisions that will shape their future success.
      </Typography>
      </Typography>
    </Container>
  );
};

export default Blog19;
