// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

if ('serviceWorker' in navigator) {
    let deferredPrompt;

    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch(error => {
                console.error('Service Worker registration failed:', error);
            });
    });

    window.addEventListener('beforeinstallprompt', event => {
        console.log('beforeinstallprompt event fired');
        event.preventDefault();
        deferredPrompt = event;
        // Optionally show your own "Add to Home Screen" button
        // set showAddToHomeScreenButton to true and handle user action accordingly
    });
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
